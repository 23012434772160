
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import moment from 'moment';
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import type ProvidersService from '@/modules/providers/providers.service';
import type CompsetsService from '@/modules/compsets/compsets.service';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import type RatesFiltersService from '@/modules/rates/rates-filters.service';
import type PromotionsService from '@/modules/promotions/promotions.service';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '@/modules/rates/rates-analysis-filters.service';
import PRICE_TYPE from '@/modules/document-filters/constants/price-type.constant';
import type Month from '@/modules/common/types/month.type';
import { InsightType } from '../../constants';
import type InsightsService from '../../insights.service';
import { DayRateInsightData, Insight } from '../../types';
import InsightGroupModel from '../../models/insight-group.model';
import RatesTable from './day-rates.table.vue';
import RateTrendsTable from './day-rate-trends.table.vue';
import MarketsTable from './day-markets.table.vue';
import RoomsTable from './day-rooms.table.vue';
import DeviceTable from './day-device.table.vue';
import MarketLeaderTable from './day-market-leader.table.vue';

/** Common popup is a wrapper for other insight day popups. Should be only used inside other popup components. */
@Component({
    components: {
        ModalWrapper,
        CustomSelect,
    },
})
export default class InsightPopup extends Vue {
    @inject(KEY.InsightsService) private insightsService!: InsightsService;
    @inject(KEY.ProvidersService) private providersService!: ProvidersService;
    @inject(KEY.CompsetsService) private compsetsService!: CompsetsService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(KEY.RatesFiltersService) private ratesFiltersService!: RatesFiltersService;
    @inject(KEY.PromotionsService) private promotionsService!: PromotionsService;
    @inject(RatesAnalysisFiltersServiceS) private ratesAnalysisFiltersService!: RatesAnalysisFiltersService;

    beforeMount() {
        this.insightsService.resetDayData();
    }

    get insightId() {
        return this.insightsService.dayInsightId;
    }

    set insightId(insId: string | null) {
        if (!insId) return;
        this.insightsService.resetDayData(insId);
    }

    get insigthIdsItems() {
        const group = this.insightsService.dayGroup as InsightGroupModel<Insight>;
        if (!group) {
            return [];
        }

        const buildLabel = (compId?: string, provider?: string, los?: number, pos?: string) => {
            let label = '';

            if (compId) {
                const compset = this.compsetsService.getCompset(compId);
                if (compset) {
                    label += `${this.$t('titles.compset')}: ${compset.name} (${this.$t(`compset.${compset.type}`)}) `;
                }
            }

            if (provider) {
                label += `${this.$t('titles.provider')}: ${this.providersService.getProviderLabel(provider)} `;
            }

            if (los) {
                label += `${this.$t('titles.los')}: ${los} `;
            }

            if (pos) {
                label += `${this.$t('titles.pos')}: ${pos} `;
            }

            return label;
        };

        const insIds = Object.keys(group.insights || {});

        return insIds.map(id => ({
            name: buildLabel(
                group.insights[id].compsetId,
                group.insights[id].provider,
                group.insights[id].los,
                group.insights[id].pos,
            ),
            value: id,
        } as Item));
    }

    get dayPopupData() {
        return this.insightsService.dayData;
    }

    get formatedDate() {
        if (!this.insightsService.dayGroup?.date) {
            return '-- ---, ----';
        }
        return moment(this.insightsService.dayGroup.date).format('DD MMM, YYYY');
    }

    get isLoading() {
        return this.insightsService.isDayDataLoading;
    }

    // Are select box with insight filters and popup's see more button should be hidden
    get isFiltersHidden() {
        return !this.insightType
            || this.insightType === InsightType.ROOMS
            || this.insightType === InsightType.MARKET_LEADER;
    }

    get insightType() {
        if (!this.insightsService.dayGroup || this.isLoading) return null;
        return this.insightsService.dayGroup.type;
    }

    get popupComponent() {
        switch (this.insightType) {
            case InsightType.RATE:
            case InsightType.LOS: return RatesTable;
            case InsightType.RATE_TRENDS: return RateTrendsTable;
            case InsightType.VISIBILITY:
            case InsightType.PROMOTION: return MarketsTable;
            case InsightType.DEVICE: return DeviceTable;
            case InsightType.ROOMS: return RoomsTable;
            case InsightType.MARKET_LEADER: return MarketLeaderTable;
            default: return null;
        }
    }

    handleSeeMore() {
        if (!this.insightsService.dayGroup || !this.insightId || !this.dayPopupData) {
            return;
        }
        const { type, date, insights } = this.insightsService.dayGroup;
        const { provider, fornovaId } = insights[this.insightId];
        const { los, pos, compsetId } = this.dayPopupData.filters;
        const { priceType, mealTypeId } = (this.dayPopupData.data[fornovaId] as DayRateInsightData)?.filters || {};
        const filters = {
            los,
            pos,
            compsetId,
            provider,
            fornovaId,
            priceType,
            mealTypeId,
        } as Record<string, string | number>;

        switch (type) {
            case InsightType.RATE:
                this.seeMoreRates(date, filters);
                break;
            case InsightType.LOS:
                this.seeMoreLos(date, filters);
                break;
            case InsightType.VISIBILITY:
                this.seeMoreMarkets(date, filters);
                break;
            case InsightType.PROMOTION:
                this.seeMorePromotions(date, filters);
                break;
            case InsightType.DEVICE:
                this.seeMoreDevices(date, filters);
                break;
            case InsightType.RATE_TRENDS:
                this.seeMoreRateTrends(date, filters);
                break;
            default:
                break;
        }
    }

    setSeeMoreDocFilters(date: string, { los, pos, compsetId }: Record<string, string | number>) {
        const dateObj = new Date(date);
        const month = dateObj.getMonth();
        const year = dateObj.getFullYear();
        this.documentFiltersService.saveMonth(month as Month);
        this.documentFiltersService.saveYear(year);
        this.documentFiltersService.saveLos(los as number);
        this.documentFiltersService.savePos(pos as string);
        this.documentFiltersService.compsetId = compsetId as string;
    }

    seeMoreRates(date: string, filters: Record<string, string | number>) {
        const day = String(new Date(date).getDate());
        this.setSeeMoreDocFilters(date, filters);
        this.ratesFiltersService.saveProvider(filters.provider as string);
        sessionStorage.setItem('insightsMealType', String(-1));
        this.ratesFiltersService.setMealType(-1);
        const { priceType } = filters as { priceType: 'LF' | 'NRF' | 'BF' };
        const priceTypes = {
            LF: PRICE_TYPE.LOWEST_FLEX,
            NRF: PRICE_TYPE.NON_REFUNDABLE,
            BF: PRICE_TYPE.BEST_FLEX,
        };
        const mainPriceType = priceTypes[priceType];
        delete priceTypes[priceType];
        const comparePriceTypes = Object.values(priceTypes).map(item => ({ name: this.$tc(`price.${item}`), value: item }));
        this.ratesFiltersService.priceType = mainPriceType;
        // To set comparison filter if compset was not changed
        this.ratesAnalysisFiltersService.comparisonKey = 'priceType';
        this.ratesAnalysisFiltersService.comparisonValues = comparePriceTypes;
        // To set comparison filter if compset was changed
        sessionStorage.setItem('comparisonKey', 'priceType');
        sessionStorage.setItem('comparisonValues', JSON.stringify(comparePriceTypes));
        this.$router.push({ name: 'hotel.rates.analysis.table.day-rate', params: { day } });
    }

    seeMoreLos(date: string, filters: Record<string, string | number>) {
        const day = String(new Date(date).getDate());
        this.setSeeMoreDocFilters(date, filters);
        this.ratesFiltersService.saveProvider(filters.provider as string);
        this.ratesFiltersService.priceType = PRICE_TYPE.LOWEST;
        sessionStorage.setItem('insightsMealType', String(-1));
        this.ratesFiltersService.setMealType(-1);
        this.$router.push({ name: 'hotel.rates.day-rate', params: { day } });
    }

    seeMoreMarkets(date: string, filters: Record<string, string | number>) {
        const day = String(new Date(date).getDate());
        this.setSeeMoreDocFilters(date, filters);
        this.$router.push({
            name: 'hotel.markets.day-markets-source',
            params: { day, source: filters.provider as string },
        });
    }

    seeMorePromotions(date: string, filters: Record<string, string | number>) {
        const day = String(new Date(date).getDate());
        this.setSeeMoreDocFilters(date, filters);
        this.promotionsService.provider = filters.provider as string;
        this.$router.push({ name: 'hotel.promotions.table.popup', params: { day } });
    }

    seeMoreDevices(date: string, filters: Record<string, string | number>) {
        const source = filters.provider as string;
        const day = String(new Date(date).getDate());
        this.setSeeMoreDocFilters(date, filters);
        this.ratesFiltersService.saveProvider(source);
        this.ratesFiltersService.priceType = PRICE_TYPE.LOWEST;
        sessionStorage.setItem('insightsMealType', String(-1));
        this.ratesFiltersService.setMealType(-1);
        const oppositeSource = /mobile_app/.test(source) ? source.replace('_mobile_app', '') : `${source.replace('_cug', '')}_mobile_app`;
        this.ratesAnalysisFiltersService.comparisonKey = 'provider';
        this.ratesAnalysisFiltersService.comparisonValues = [{ name: this.providersService.getProviderLabel(oppositeSource), value: oppositeSource }];
        this.$router.push({ name: 'hotel.rates.analysis.table.day-rate', params: { day } });
    }

    seeMoreRateTrends(date: string, filters: Record<string, string | number>) {
        const day = String(new Date(date).getDate());
        this.setSeeMoreDocFilters(date, filters);
        this.ratesFiltersService.saveProvider(filters.provider as string);
        this.ratesFiltersService.priceType = PRICE_TYPE.LOWEST;
        sessionStorage.setItem('insightsMealType', String(-1));
        this.ratesFiltersService.setMealType(-1);
        this.$router.push({ name: 'hotel.rates.day-rate', params: { day } });
    }
}
